<template>
  <div class="aboutyouTrainingdays">
    <div class="aboutyouTrainingdays__title">
      {{ $t('aboutProgress.trainingDaysTitle') }}
    </div>

    <p class="aboutyouTrainingdays__body">
      {{ $t('aboutProgress.trainingDaysBody') }}
    </p>

    <Calender v-on:openWorkout="openWorkout" />
  </div>
</template>

<script>
import { defineAsyncComponent, markRaw } from 'vue';
import Calender from '@/components/aboutyou/progress/Calender';
import { ModalBus } from '@/eventBus';

const WorkoutProgressModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/aboutyou/progress/WorkoutProgressModal' /* webpackChunkName: "workoutProgressModal" */),
}));

export default {
  components: {
    Calender,
  },

  methods: {
    openWorkout(workout) {
      ModalBus.emit('open', {
        component: WorkoutProgressModal,
        props: { workout },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.aboutyouTrainingdays {
  padding: 0 0 16px;
}

.aboutyouTrainingdays__title {
  @include heading-4;
  margin: 0 0 rem(16px) 0;
}

.aboutyouTrainingdays__body {
  @include paragraph;
  margin: 0 0 rem(20px) 0;
}
</style>
