<template>
  <div class="calenderControls">
    <base-form-select
      id="month"
      v-bind:options="monthOptions"
      v-model="selectedMonth"
      class="calenderControls__select"
    />

    <base-form-select
      id="year"
      v-bind:options="yearOptions"
      v-model="selectedYear"
      class="calenderControls__select"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import BaseFormSelect from '@/components/forms/BaseFormSelect';

export default {
  components: {
    BaseFormSelect,
  },

  props: {
    workouts: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      yearOptions: [],
      monthOptions: [],
      selectedDate: new Date(),
      selectedYear: null,
      selectedMonth: null,
    };
  },

  watch: {
    selectedDate() {
      this.$emit('setDate', this.selectedDate);
    },

    selectedMonth(newValue) {
      this.selectedDate = moment(this.selectedDate).set('month', newValue).toDate();
    },

    selectedYear(newValue) {
      this.selectedDate = moment(this.selectedDate).set('year', newValue).toDate();
    },
  },

  computed: {
    ...mapState('member', ['member']),
  },

  created() {
    this.selectedYear = this.selectedDate.getFullYear();
    this.selectedMonth = this.selectedDate.getMonth();

    const memberStartYear = new Date(this.member.start_date).getFullYear();
    const numYearsMember = (new Date().getFullYear() - memberStartYear) + 1;

    this.yearOptions = Array.from({ length: numYearsMember }, (e, i) => {
      return {
        name: memberStartYear + i,
        value: memberStartYear + i,
      };
    });

    this.monthOptions = Array.from({ length: 12 }, (e, i) => {
      const date = new Date(null, i, null);

      return {
        name: date.toLocaleDateString('NL', { month: 'long' }),
        value: date.getMonth(),
      };
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.calenderControls {
  display: flex;
  align-items: center;
}

.calenderControls__select {
  &:first-child {
    margin: 0 rem(10px) 0 0;
  }
}
</style>
