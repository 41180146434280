<template>
  <div
    v-bind:class="{
      'calenderItem': true,
      'calenderItem--inactive': !data && !overrideLabel,
      'calenderItem--today': isToday,
      'calenderItem--multiple': hasMultipleWorkouts,
      'calenderItem--weekDayLabel': !!overrideLabel,
    }"
  >
    {{ label }}

    <div
      v-if="data"
      class="calenderItem__ratingItem"
    >
      <div
        v-for="(workout, index) in data.workouts"
        v-bind:key="index"
        class="calenderItem__ratingItem__icon"
      >
        <rating-icon
          v-bind:rating="workout.feedback"
          v-on:click="$emit('openWorkout', workout)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import RatingIcon from '@/components/RatingIcon';

export default {
  components: {
    RatingIcon,
  },

  props: {
    data: {
      type: [Object, null],
    },

    overrideLabel: {
      type: String,
    },
  },

  computed: {
    label() {
      if (this.overrideLabel) {
        return this.overrideLabel;
      }

      if (!this.data) {
        return '-';
      }

      return this.data.date.getDate();
    },

    hasMultipleWorkouts() {
      if (!this.data) {
        return false;
      }

      return this.data.workouts.length > 1;
    },

    isToday() {
      if (!this.data) {
        return false;
      }

      return moment(this.data.date).isSame(new Date(), 'day');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.calenderItem {
  @include small;
  position: relative;
  padding: rem(10px) 0;
  text-align: center;
  border-right: 1px solid $color-beige;

  &--weekDayLabel {
    @include note--bold;
  }

  &--inactive {
    color: $color-grey--medium;
  }

  &--multiple {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
      cursor: pointer;
    }
  }

  &--today {
    color: $color-blue;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      height: 30px;
      width: 30px;
      border: 1px solid $color-blue;
      border-radius: 100%;
      transform: translate(-50%, -50%);
      z-index: 0;
    }
  }

  .calenderItem__ratingItem {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    transform: translate(-50%, -50%);
    z-index: 5;
  }

  .calenderItem__ratingItem__icon {
    position: relative;
    margin: 0 rem(-8px) 0 0;
    height: 33px;
    width: 33px;
    transition: all 0.3s;
    cursor: pointer;
  }
}
</style>
