<template>
  <Field
    v-bind:name="$attrs.id"
    v-slot="{ field }"
    class="formSelect"
    type="select"
    as="div"
  >
    <select
      v-bind="{ ...field, ...$attrs }"
      v-bind:id="$attrs.id"
      class="formSelect__field"
    >
      <option>
        {{ placeholder }}
      </option>

      <option
        v-for="(option, index) in options"
        v-bind:key="index"
        v-bind:value="option.value"
      >
        {{ option.name }}
      </option>
    </select>

    <font-awesome-icon
      icon="chevron-down"
      class="formSelect__icon"
    />
  </Field>
</template>

<script>
import { Field } from 'vee-validate';

export default {
  components: {
    Field,
  },

  props: {
    options: {
      type: Array,
      required: true,
      validator: value => value.every(({ name, value }) => name !== undefined && value !== null ),
    },

    placeholder: {
      type: String,
      default: '--',
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.formSelect {
  position: relative;
}

.formSelect__field {
  @include paragraph;
  padding: rem(8px) rem(48px) rem(8px) rem(8px);
  width: 100%;
  background: $color-white;
  border: 1px solid $color-beige--dark;
  border-radius: rem(6px);
  color: $color-text;
  appearance: none;
}

.formSelect__icon {
  position: absolute;
  top: rem(14px);
  right: rem(16px);
  color: $color-beige--dark;
}
</style>