<template>
  <div class="rating-emoticon">
    <picture class="rating-emoticon__picture">
      <img
        v-if="rating === null || rating === '0'"
        src="@/assets/rating/rating-0.png"
        class="rating-emoticon__image"
      />

      <img
        v-for="n in 10"
        v-bind:key="n"
        v-show="rating === n || rating === `${n}`"
        v-bind:src="require(`@/assets/rating/rating-${n}.png`)"
        v-bind:class="{
          'rating-emoticon__image': true,
          [`rating-emoticon__image--rating${n}`]: true,
        }"
      />
    </picture>
  </div>
</template>

<script>
export default {
  props: {
    rating: {
      type: [Number, String],
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.rating-emoticon {
  max-width: 100%;
  max-height: 100%;
}

.rating-emoticon__picture {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rating-emoticon__image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
  border: 2px solid map-get($rating-colors, 0);
  background: map-get($rating-colors, 0);

  @for $i from 1 through 10 {
    &--rating#{$i} {
      border-color: map-get($rating-colors, $i);
      background: map-get($rating-colors, $i);
    }
  }
}
</style>
