<template>
  <div class="aboutYouProgress">
    <base-section>
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column>
            <about-you-trainingdays />
          </base-column>
        </base-row>
      </base-grid>
    </base-section>

    <base-section>
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column>
            <about-you-programs />
          </base-column>
        </base-row>
      </base-grid>
    </base-section>
  </div>
</template>

<script>
import BaseSection from '@/components/grid/BaseSection';
import BaseGrid from '@/components/grid/BaseGrid';
import BaseRow from '@/components/grid/BaseRow';
import BaseColumn from '@/components/grid/BaseColumn';
import AboutYouTrainingdays from '@/components/aboutyou/progress/AboutYouTrainingdays';
import AboutYouPrograms from '@/components/aboutyou/progress/AboutYouPrograms';

export default {
  components: {
    BaseSection,
    BaseGrid,
    BaseRow,
    BaseColumn,
    AboutYouTrainingdays,
    AboutYouPrograms,
  },
};
</script>
