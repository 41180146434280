<template>
  <div class="programCard">
    <div class="programCard__imageWrapper">
      <div
        class="programCard__imageLink"
        v-on:click="onClick"
      >
        <img
          v-bind:src="`${program.image_mobile}?w=450&q=80`"
          v-bind:srcset="`${program.image_mobile}?w=450&q=80, ${program.image_mobile}?w=900&q=80 2x`"
          class="programCard__image"
        />

        <div class="programCard__imageContent">
          <div class="programCard__categories">
            <category-label
              v-for="(category, index) in categories"
              v-bind:key="index"
              v-bind:category="category"
              class="programCard__category"
            />
          </div>

          <div
            v-if="active && program.active_workout"
            class="programCard__progress"
          >
            <Progressbar
              v-bind:percentage="progressBarPercentage"
              class="programCard__progressbar"
            />

            <div>
              {{
                $t('programCard.periodXofY', {
                  x: program.periods.findIndex(({ id }) => id === program.active_period) + 1,
                  y: program.periods.length,
                })
              }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="programCard__contentWrapper">
      <div class="programCard__content">
        <div class="programCard__content__title">
          <span class="programCard__content__title__trainer">
            {{ program.name }}
          </span>

          <span
            v-if="program.trainers[0]"
          >
            {{ $t('programCard.withTrainer', { trainer: program.trainers[0].name }) }}
          </span>
        </div>

        <div class="programCard__stats">
          <div class="programCard__statsItem">
            <font-awesome-icon icon="person-running" />

            {{ $t('programCard.numWorkoutsLabel', { numWorkouts: getTotalWorkouts }) }}
          </div>

          <div class="programCard__statsItem">
            <font-awesome-icon icon="signal" />

            {{ $tc('programCard.numLevelsLabel', program.number_intensities, { numLevels: program.number_intensities }) }}
          </div>
        </div>

        <p
          v-html-safe="program.description"
          class="programCard__description"
        />
      </div>

      <div class="programCard__buttonContainer">
        <base-button
          v-bind:modifiers="active ? 'primary' : 'secondary'"
          v-on:click="onClick"
        >
          {{ getButton }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, markRaw } from 'vue';
import { ModalBus } from '@/eventBus';
import BaseButton from '@/components/BaseButton';
import Progressbar from '@/components/Progressbar';
import CategoryLabel from '@/components/CategoryLabel';

const StartProgramModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/program/StartProgramModal' /* webpackChunkName: "startProgramModal" */),
}));

export default {
  components: {
    BaseButton,
    Progressbar,
    CategoryLabel,
  },

  props: {
    program: {
      type: Object,
      required: true,
    },

    active: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    progressBarPercentage() {
      const numPeriods = this.program.periods.length;
      const activePeriodIndex = this.program.periods.findIndex(({ id }) => id === this.program.active_period);

      return (100 / numPeriods) * (activePeriodIndex + 1);
    },

    getTotalWorkouts() {
      return (this.program.periods.length * this.program.days_per_week);
    },

    categories() {
      const categories = [];

      this.program.categories.forEach(category => {
        // FIXME Rewrite the category in the database so we can remove the statement
        // category.name === "Conditie" ? categories.push("Fit worden") : categories.push(category.name);

        categories.push({
          name: category.name === 'Conditie' ? 'Fit worden' : category.name,
          slug: category.slug,
        });
      });

      return categories;
    },

    getButton() {
      if (this.program.active_period) {
        if (this.program.active_workout) {
          return this.$t('programCard.activeButtonLabel');
        }

        return this.$t('programCard.completedButtonLabel');
      }

      return this.$t('programCard.inactiveButtonLabel');
    },
  },

  methods: {
    onClick() {
      if (this.program.active_period) {
        this.$router.push({
          name: 'program',
          params: {
            programId: this.program.id,
            periodId: this.program.active_period,
          },
        });

        return;
      }

      ModalBus.emit('open', {
        component: StartProgramModal,
        props: { programId: this.program.id },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/lib";

.programCard__description {
  &,
  & p {
    @include label;
    display: none;

    @media (min-width: $breakpoint-md) {
      display: block;
    }
  }

  em {
    @include note;
    display: block;
    margin: 1rem 0;
    font-style: normal;
  }
}
</style>

<style lang="scss" scoped>
@import "@/scss/lib";

.programCard {
  display: flex;
  flex-direction: column;
  margin-bottom: $spacing-md;
  cursor: default;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: $breakpoint-md) {
    flex-direction: row;
  }
}

.programCard__imageWrapper {
  order: 2;
  position: relative;

  @media (min-width: $breakpoint-md) {
    width: calc(100% / 12 * 5);
    order: 1;
  }
}

.programCard__imageLink {
  display: block;
  cursor: pointer;
}

.programCard__contentWrapper {
  @media (min-width: $breakpoint-md) {
    width: calc(100% / 12 * 7);
    order: 2;
  }
}

.programCard__image {
  display: block;
  position: relative;
  width: 100%;
  height: 15.375rem;
  border-radius: $border-radius-md;
  object-fit: cover;
  overflow: hidden;
  z-index: 1;
}

.programCard__imageContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: $border-radius-md;
  overflow: hidden;
  z-index: 2;
}

.programCard__categories {
  padding: $spacing-sm;
}

.programCard__category {
  margin: 0 0.25rem 0 0;

  &:last-child {
    margin: 0;
  }
}

.programCard__progress {
  @include note;
  padding: $spacing-sm;
  width: 100%;
  height: 4rem;
  color: $color-white;
  background: linear-gradient(-180deg, rgba(0, 0, 0, 0) 1%, rgb(0, 0, 0) 97%);
}

.programCard__progressbar {
  margin-bottom: 0.75rem;
  width: 100%;
}

.programCard__content {
  margin: 0 0 1rem 0;
  padding: $spacing-sm 0 0 0;

  @media (min-width: $breakpoint-md) {
    padding: $spacing-sm 0 0 $spacing-sm;
  }
}

.programCard__content__title {
  @include paragraph;
  margin-bottom: 0.25rem;
  color: $color-text;

  span {
    display: inline-block;
    margin-right: $spacing-xs;
  }
}

.programCard__content__title__trainer {
  @include heading-3;
}

.programCard__stats {
  display: flex;
  margin-bottom: $spacing-sm;
}

.programCard__statsItem {
  @include note;
  margin-right: $spacing-sm;
  color: $color-grey;

  .svg-inline--fa {
    position: relative;
    top: 0.125rem;
    margin-right: 0.1rem;
    height: 1rem;
    color: $color-beige--dark;
  }
}

.programCard__buttonContainer {
  display: none;
  padding-left: $spacing-sm;

  @media (min-width: $breakpoint-md) {
    display: block;
  }
}
</style>
