<template>
  <div>
    <calender-controls
      v-bind:workouts="workouts"
      v-on:setDate="setDate"
    />

    <div class="calender">
      <calender-item
        v-for="(weekDayLabel, index) in weekDayLabels"
        v-bind:key="`a${index}`"
        v-bind:override-label="weekDayLabel"
      />

      <calender-item
        v-for="(calendarDay, index) in calendarDays"
        v-bind:key="`b${index}`"
        v-bind:data="calendarDay"
        v-on:openWorkout="openWorkout"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import CalenderControls from '@/components/aboutyou/progress/CalenderControls';
import CalenderItem from '@/components/aboutyou/progress/CalenderItem';

export default {
  components: {
    CalenderControls,
    CalenderItem,
  },

  data() {
    return {
      isLoading: true,
      selectedDate: new Date(),
      weekDayLabels: ['ma', 'di', 'wo', 'do', 'vr', 'za', 'zo'],
    };
  },

  computed: {
    ...mapState('member', ['workouts']),

    calendarDays() {
      const year = this.selectedDate.getFullYear();
      const month = this.selectedDate.getMonth();
      const firstDay = new Date(year, month, 1).getDay() - 1; // day of the week index
      const lastDay = new Date(year, month + 1, 0).getDate(); // number of days in this month

      const calenderDays = Array.from({ length: firstDay + lastDay }, (value, key) => {
        if (key < firstDay) {
          return null;
        }

        const date = new Date(year, month, (key + 1) - firstDay);
        const workouts = this.workouts.filter(({ finished_at }) => moment(finished_at).isSame(date, 'day'));

        return { date, workouts };
      });

      return calenderDays;
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    ...mapActions('member', ['fetchWorkouts']),

    setDate(date) {
      this.selectedDate = date;
      this.fetchData();
    },

    async fetchData() {
      this.isLoading = true;

      await this.fetchWorkouts({
        year: this.selectedDate.getFullYear(),
        month: this.selectedDate.getMonth() + 1,
      });

      this.isLoading = false;
    },

    openWorkout(data) {
      this.$emit('openWorkout', data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.calender {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  margin: 32px 0 0 0;
  max-width: 620px;
  border-left: 1px solid $color-beige;
}
</style>
