<template>
  <div class="aboutyou-trainingdays">
    <base-heading type="4">
      {{ $t('aboutProgress.programsTitle') }}
    </base-heading>

    <p class="aboutyou-trainingdays-paragraph">
      {{ $t('aboutProgress.programsBody') }}
    </p>

    <template v-if="activePrograms.length">
      <program-card
        v-for="program in activePrograms"
        v-bind:key="program.id"
        v-bind:program="program"
        v-bind:active="true"
      />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ProgramCard from '@/components/program/ProgramCard';
import BaseHeading from '@/components/BaseHeading';

export default {
  components: {
    BaseHeading,
    ProgramCard,
  },

  computed: {
    ...mapGetters('program', ['activePrograms']),
  },

  mounted() {
    this.getPrograms();
  },

  methods: {
    ...mapActions({
      getPrograms: 'program/fetchAll',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.aboutyou-trainingdays-paragraph {
  @include paragraph;
  margin: 0 0 rem(20px) 0;
}
</style>